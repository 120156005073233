.forgot-password {
  .forgot-password__content {
    text-align: left;
    .sent-info__text,
    .password-reset__text,
    .password-reset__form {
      margin-bottom: 0.8em;
    }
    .password-reset__item {
      margin-bottom: 1em;
      input {
        width: 50%;
        display: block;
      }
    }
  }
}

.account__section {
  .account-page__content {
    float: left;
    width: 75%;
    .account-profile__email {
      word-wrap: break-word;
    }
    .profile-info__header,
    .optional-info__header {
      @include thfont-bold();
    }
    .section-header__header {
      @include thfont-bold();
    }
  }
  &.past-purchases-page {
    .past-purchases-data-header__item {
      @include thfont-bold();
      &.order-status {
        width: 30%;
      }
      &.created-date {
        width: 22%;
      }
      &.price {
        width: 20%;
      }
    }
    .past-purchases-data-item__item {
      &.price {
        width: 20%;
      }
      &.created-date {
        width: 22%;
      }
      &.order-status {
        width: 20%;
      }
      &.view-details {
        width: 10%;
      }
    }
    overflow: auto;
    max-width: 1024px;
    margin: 40px auto;
    .product-info {
      border-top: none;
      &__price {
        float: none;
        width: 25%;
      }
      &__details {
        margin-bottom: 20px;
      }
    }
  }
  .past-purchases-page__content {
    width: auto;
    overflow: hidden;
    padding-bottom: 1px;
    .product-item {
      &:first-child {
        border-top: none;
      }
      border-top: 1px $color-gray-border solid;
    }
  }
  .sidebar-right {
    float: right;
  }
  .continue-buttons {
    float: right;
  }
  .info-text {
    margin: 10px 0;
  }
  .account__page-header {
    padding-bottom: 20px;
    border-bottom: 1px solid $color-black;
    margin-bottom: 10px;
  }
  .profile-info__item {
    margin-left: 0;
  }
  #registration-wrapper {
    #address_form_container {
      border: 0;
      padding-left: 0;
    }
  }
  .optional-info {
    padding-bottom: 20px;
    .gender_container {
      margin-left: 10px;
      label,
      span {
        margin-right: 10px;
      }
    }
  }
  .address-book-page__content {
    .payment-info__header {
      @include thfont-bold();
    }
    .address-book__header {
      @include thfont-bold();
    }
    .section-head {
      @include thfont-bold();
    }
  }
}

#address_form_container {
  .default_shipping {
    .label-content {
      padding-right: 5px;
    }
  }
  input.first-name {
    margin-top: 15px;
  }
}

.sign-in {
  input.registration__email {
    width: 100%;
  }
}

.order-details {
  .order-totals {
    width: 40%;
    .price {
      padding-left: 10px;
      text-align: right;
    }
  }
}

.favorites-page {
  .favorites {
    &__recommended-products {
      .formatted_price {
        min-height: 50px;
      }
      .product_name a {
        border: none;
      }
    }
    &__order-by {
      margin-top: 18px;
      margin-bottom: 0;
    }
  }
  @media only screen and (max-device-width: 768px) {
    .favorites__recommended-product {
      .swatch-container {
        margin-right: 4px;
      }
      .product_name {
        min-height: 96px;
      }
    }
    .favorites__recommended-product-actions {
      a.favorites__recommended-product-add-to-favorites {
        margin-right: 0px;
        float: left;
      }
      a.favorites__recommended-product-add-to-cart {
        width: 82%;
        padding: 15px 0px 15px 0px;
      }
    }
  }
}

.account #cboxClose {
  top: 10px;
  right: 5px;
}

.past-purchases-page {
  .past-purchases__shopping {
    margin: 0;
  }
}

.favorites-page__tabs {
  right: 0;
}

.favorites {
  .my-lists__list-actions {
    .my-lists__list-link--email,
    .my-lists__list-link--print,
    .my-lists__list-link--share {
      vertical-align: top;
    }
  }
}

.sign-in-page {
  &__header,
  &__login,
  .section-head__header {
    @include thfont-bold();
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .account__section {
    .account-page__content {
      width: 70%;
    }
  }
}

.ie8 {
  .favorites-page__content {
    .favorites .my-lists__list-actions {
      .my-lists__list-link--print,
      .my-lists__list-link--email {
        float: left;
      }
      .my-lists__list-link--share {
        margin-left: 10px;
      }
    }
  }
}

.fade-in {
  background: url('/media/export/images/global/ajax-loader-skybox.gif') no-repeat center left;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  padding: 0px 1px 9px 0px;
}
