$ab-ipad: (width 350px 780px);

.appt-book {
  .appointment-booking-main-collection__header {
    .appointment-booking-main-collection__header-content {
      height: auto !important;
      .virtual__appointment--btn {
        margin-top: 15px;
      }
    }
  }
  #appointment-book-sections {
    .appointment-booking-review__account-signup-form {
      .signup__mobile-prefix {
        width: 60px !important;
      }
      .signup__mobile-phone {
        padding-left: 55px !important;
      }
    }
    .appointment-booking-review__content {
      .appointment-booking-review__book {
        @include breakpoint($medium-up) {
          .appointment-booking-review__map {
            width: 50%;
          }
          .appointment-booking-review__details {
            width: 100%;
          }
        }
      }
    }
  }
}

.appointments-page.account__section {
  .appt-book-no-appts-content {
    @include breakpoint($ab-ipad) {
      .mobile-hidden {
        display: block !important;
        width: 70% !important;
      }
    }
    .no-appts-content {
      width: 55% !important;
    }
  }
  padding-left: 2% !important;
  padding-right: 2% !important;
}

.appointment-booking-schedule {
  &__day-time-container {
    table {
      display: inline-table;
      float: none !important;
      @include breakpoint($portrait-up) {
        margin: 0 4px !important;
      }
    }
  }
}

#past-appt-body {
  .zoom-meeting {
    display: none;
  }
}
