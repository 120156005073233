a.selectBox {
  &.error {
    border: 1px solid $color-red;
  }
}

.margin_top_bottom {
  margin: 0.8em 0;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.clear {
  clear: both;
}

ul.links_list {
  li.link {
    border: 0;
    width: 100%;
  }
}

.error {
  color: $color-red;
}
/* Live Chat */
.site-header {
  .lpStaticButton {
    width: auto;
    margin: 0px;
    td {
      border: none;
      padding: 0px;
    }
    .lpPoweredBy,
    .lpEmtStarRating {
      display: none;
    }
    a img {
      position: relative;
    }
  }
}

.search-results__header {
  .search-form {
    &__results-count {
      line-height: 35px;
      #{$rdirection}: 181px;
    }
    input[type=submit].search-form__submit {
      margin-#{$ldirection}: 0;
    }
  }
}

.section-store-locator {
  .store-locator__links-container {
    .store-locator__dutyfree {
      display: none;
    }
  }
}

.site-footer {
  .site-footer {
    &__column {
      width: 28%;
      padding: 0 15px;
      .menu__item p {
        margin: 0;
        line-height: 1;
      }
      &:last-child {
        width: 16%;
        padding: 0 0 0 20px;
      }
      .site-email-signup__form {
        input[type='submit'] {
          padding: 0 8px;
        }
      }
    }
    &__payment-image {
      img {
        width: 75px;
        margin-right: 20px;
      }
    }
  }
}

.lp_buttons_area {
  .lp_submit_button,
  .lp_confirm_button,
  .lp_close_survey_button {
    &:hover {
      background: $color-black !important;
    }
  }
}
