.checkout {
  ol.checkout-progress {
    li {
      float: left;
      margin-left: 1.5em;
      width: 12.5em;
      list-style-type: decimal;
      margin-top: 1.5em;
    }
  }
  .submit {
    text-align: right;
    margin-bottom: 1.5em;
  }
  .cod-message {
    em {
      color: $color-red;
    }
  }
  .left.checkout__content {
    .disabled.continue-checkout {
      background-color: $color-gray-border;
    }
    .cart-items {
      .sub__total {
        float: right;
        margin: 0.8em 0;
      }
      .cart-item__qty {
        width: 16%;
      }
      .cart-item__total {
        width: 22%;
      }
    }
    .shipping-page-info-panel {
      .address-info {
        float: left;
        margin: 0 20px 10px 0;
        width: 30%;
      }
      .address_controls {
        float: left;
        width: 65%;
      }
      #delivery-instructions {
        textarea.error {
          border: 1px solid $color-red;
        }
      }
    }
    .trans_detail_item {
      float: left;
      margin-bottom: 25px;
      margin-right: 25px;
      position: relative;
      width: 280px;
      word-wrap: break-word;
      h4 {
        border-bottom: 1px solid $color-gray-border;
        font-weight: bold;
        margin-bottom: 10px;
        padding-bottom: 5px;
        text-transform: none;
      }
      .change_link {
        position: absolute;
        right: 0;
        top: 0px;
      }
      &#delivery-options {
        width: 230px;
        margin-right: 10px;
      }
      &#gift-options {
        margin-right: 0;
      }
      &.shipping_address_container,
      &.billing_address_container {
        min-height: 250px;
        margin-bottom: 0px;
      }
    }
  }
  //Control directive to highlight checkout breadcrumb
  $checkout: (shipping, review, payment, confirm);
  @each $value in $checkout {
    &.#{$value} {
      .checkout-progress__#{$value} {
        font-weight: bold;
      }
    }
  }
  #recommended-products-panel {
    .recommended-products-panel__content {
      .recommended-product-items {
        .recommended-item {
          .description {
            div {
              margin: 0px 0px 10px 0px;
            }
            .color .swatch {
              margin-right: 10px;
            }
          }
        }
      }
    }
  }
  &.shipping,
  &.review {
    li[id='offer_bogo..'] {
      display: none;
    }
  }
  .checkout__sidebar {
    .links-panel {
      padding: 16px 20px;
      background: #eff0f0;
    }
    section {
      margin-bottom: 10px;
    }
    #offer-code-panel {
      #offer_code .offer_code_form_container p {
        margin: 15px 0px;
      }
    }
  }
}

.address-form {
  ul.error_messages {
    li {
      color: $color-red;
    }
  }
}

.address-form__item {
  label.error {
    border: 0;
  }
}

#return-user {
  a.js-forgot-password {
    display: block;
    margin-bottom: 10px;
  }
}

.samples-page {
  .product {
    .sample-select-checkbox {
      display: block !important;
      visibility: visible;
    }
    .sample-select-button {
      display: block !important;
    }
  }
}

.checkout-confirmation-page {
  p.order_to_history {
    padding-bottom: 10px;
  }
  .email-address {
    margin-bottom: 10px;
  }
  #return-user {
    margin-top: 20px;
  }
}

#address_form_container {
  #address {
    position: relative;
  }
}

.samples-page {
  .product {
    min-height: 463px;
    padding: 0;
  }
  #samples-panel {
    .samples-buttons.bottom {
      display: block;
    }
  }
}

#shipping-info-panel {
  .align_left {
    text-align: left;
  }
}

.checkout {
  .left.checkout__content {
    .trans_detail_item.billing_address_container {
      min-height: 310px;
    }
    .trans_detail_item.gift_container {
      h4 {
        width: 74%;
      }
    }
  }
}

.order-details__item {
  h4 {
    font-size: 18px;
  }
}

.order-confirmation__item {
  h4 {
    font-size: 18px;
  }
}

.order-details-page__content {
  .order-details {
    .order-totals {
      width: 50%;
      padding-right: 35px;
    }
    .cart-item__qty-label {
      display: none;
    }
  }
  .checkout__panel-title {
    font-size: 18px;
  }
}

.cart_overlay {
  .cart-confirm-wrapper {
    .cart-products {
      .prod {
        .prod-info {
          width: 175px;
        }
        .price {
          width: 100px;
          letter-spacing: 2.5px;
        }
      }
    }
    .subtotal {
      .left-copy {
        width: 25%;
        word-wrap: word-wrap;
      }
      .right-copy {
        width: 75%;
        .sub_t_text {
          width: 35%;
          word-wrap: break-word;
        }
        .sub_t {
          width: 53%;
          text-align: right;
          float: left;
        }
      }
      .currency_type {
        width: 12%;
        float: right;
        display: block;
      }
    }
  }
}

.order-confirmation {
  .order-details {
    .cart-item {
      &__desc {
        width: 27%;
      }
      &__qty {
        width: 22%;
        &-label {
          display: inline-block;
        }
      }
      &__total {
        text-align: center;
      }
    }
  }
}

.pg_wrapper {
  .fade-in {
    background: url('/media/export/images/global/ajax-loader.gif') no-repeat center center #000000;
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    opacity: 0.5;
  }
}

.adpl {
  label {
    display: block !important;
    visibility: visible !important;
  }
  &.checkout__new-account,
  &.checkout__return-user,
  &.account__new-account,
  &.account__return-user {
    input[type='password'],
    input[type='text'],
    input[type='email'] {
      margin-bottom: 0;
      margin-top: 20px;
      width: 100%;
    }
    .form-submit.checkout_sign_in {
      margin-top: 20px;
    }
  }
  &#checkout_shipping {
    .form_element,
    .gift_message {
      margin-bottom: 20px;
    }
    .required_mark {
      float: left;
    }
    .first_name_container {
      input[type='text'] {
        margin-top: 15px;
      }
    }
  }
}

.viewcart,
.account,
.samples,
.index,
.confirm {
  .adpl {
    .title-type-option,
    .radio-buttons,
    .ship-type-option {
      label {
        display: inline-block !important;
        float: left;
      }
    }
  }
}
