.quickshop__container {
  .product__details {
    .ask__answer {
      display: none;
    }
  }
}

.search-results__header--desktop {
  #perlgem-search-form {
    .search-form__fields {
      input#search {
        padding-right: 100px;
      }
    }
  }
}

.ie8 {
  .site-footer-contact__item--phone {
    padding-bottom: 18px;
  }
  .site-footer-contact__item--email {
    padding-top: 18px;
    padding-bottom: 18px;
  }
}

.customer-service {
  .customer-service-quick-info {
    .customer-service-quick-info__list-item--chat {
      width: auto;
      margin: 0 33px;
    }
    .customer-service-quick-info__list-item--email {
      .customer-service-quick-info__link {
        margin-top: 0px;
      }
    }
    .customer-service-quick-info__list-item--phone {
      .customer-service-quick-info__link {
        margin-top: 10px;
      }
    }
  }
}

.product__header {
  #BVQASummaryContainer {
    clear: left;
  }
}

.site-nav {
  .menu__link--lvl-2 {
    @include thfont-bold();
  }
  .menu__link--lvl-1 {
    @include thfont-bold();
  }
}

.makeup-lessons-item__thumb {
  .makeup-lessons-item__thumb-image {
    width: auto;
  }
}

.site-footer {
  .site-footer-social-links__item {
    margin-right: 0;
  }
  .trustmark-logo {
    width: 100px;
  }
}

.cart_overlay {
  .cart-products {
    .price {
      .currency_type {
        display: none;
      }
    }
  }
}

.past-purchases-page {
  .order-details {
    .order-totals {
      th {
        padding-top: 8px;
        padding-bottom: 0px;
      }
      td {
        padding-top: 8px;
      }
    }
  }
}

.bazaarvoice_ratings_n_reviews {
  .BVRRQuickTakeSection {
    display: none;
  }
}

.search-form {
  .product__price {
    width: 80px;
  }
}

.spp {
  .product--full {
    .product__unit-price {
      display: none;
    }
  }
}

.site-header {
  .site-header__hr {
    background-color: black;
  }
}

.nude_finish {
  .product_collection_hero {
    &__content-item:nth-child(n + 3) {
      width: 100%;
      border-right: 0px;
      .product_angled_brush {
        background-position: center 64%;
        height: 300px;
      }
    }
  }
}

.section-long-wear-collection {
  .product_collection_hero__body {
    display: none;
  }
  .product_collection_hero__content-item {
    &:first-child {
      float: right;
    }
    &:nth-child(2) {
      .product-collection-product__callout {
        color: #ff469a;
      }
    }
    &:nth-child(5) {
      clear: both;
    }
    &:nth-child(12) {
      margin: 50px 0 30px;
    }
    &:nth-child(13) {
      margin-top: 0;
    }
    .product-collection-product__product,
    .product-image {
      border: none;
    }
  }
}

.bobbi-masks {
  .product_collection_hero {
    &__content-item:first-child {
      min-height: 380px;
    }
  }
}

.page-node-24366 {
  .field-content {
    max-width: 1024px;
    margin: 0 auto;
    .product-collection-product {
      text-align: center;
      border-top: 2px solid;
      margin: 0px auto 20px;
      h2.product-collection-product__product-name {
        margin: 40px auto;
        text-transform: uppercase;
        font-size: 40px;
        width: 500px;
        line-height: 1;
      }
      h2.product_collection__header {
        margin: 20px auto;
      }
      &:nth-child(3) {
        h2 {
          display: none;
        }
        .product-collection-product__shop-details {
          display: none;
        }
      }
      &:nth-child(5) {
        .product-collection-product__price {
          display: none;
        }
      }
      p.product-collection-product__description {
        font-size: 24px;
      }
      h3 {
        text-align: center;
        font-size: 24px;
        font-weight: bold;
        margin: 20px auto;
      }
      li.product-collection-product__shadename {
        width: 33%;
        float: left;
        margin: 10px 0;
        text-align: left;
        span.shade-picker__shade {
          float: left;
        }
      }
      .product-collection-product__shop-details {
        clear: both;
        text-align: center;
      }
    }
  }
}

.page-node-25452 {
  .content {
    .block_container__content {
      section {
        float: left;
        width: 50%;
      }
      .text-block_title {
        margin: 0;
      }
    }
  }
}
/* Power reviews */

#pr-reviewdisplay {
  .p-w-r {
    .pr-review-display {
      .pr-helpful {
        &-yes {
          .pr-helpful-count {
            &:before {
              content: 'ใช่ ';
              visibility: visible;
            }
          }
        }
        &-no {
          .pr-helpful-count {
            &:before {
              content: 'ไม่ใช่ ';
              visibility: visible;
            }
          }
        }
      }
    }
  }
}

.tiny-waitlist-overlay {
  .field {
    border-top: 1px solid gray;
    padding-top: 10px;
    .align-r {
      display: inline-block;
    }
  }
  .email_input {
    display: inline-block;
  }
  .waitlist-header {
    display: block;
    font-weight: bold;
    padding-bottom: 10px;
  }
}

.waitlist_thankyou_message {
  margin: 90px 60px;
  font-family: 'Brandon Text Bold';
}

.appt-book-page-header-content {
  position: inherit;
  margin: 0 auto;
  .inner {
    position: inherit;
    transform: none;
  }
  height: auto !important;
  margin-bottom: 15px;
  &__subhead {
    h2,
    h4 {
      display: none;
    }
  }
}

.appt-book-page-header-bg {
  display: block;
}

@include breakpoint(($tablet-down)) {
  .appt-book {
    .appointment-select {
      .appointments-container {
        .selects-container {
          .select-container {
            width: 100%;
          }
        }
      }
    }
    &-overlay {
      &.appt-book-datepicker-overlay {
        .overlay-content-container {
          width: 100%;
          left: 0;
          margin-left: 0;
        }
      }
    }
  }
}

.appt-book {
  .appointment-select {
    .appointments-container {
      .book-appt-container {
        .registration__terms {
          float: right;
        }
      }
    }
  }
  &-section-content {
    &.location-select-section {
      .appt-book-location-selector {
        .form-container {
          .selectBox {
            .selectBox-label {
              font-size: 16px;
              font-weight: bold;
            }
          }
        }
      }
    }
  }
  &.confirmation {
    .appt-book-page-header-content {
      width: 70%;
    }
  }
}

.js-store-locator-hero {
  #store-search-controls {
    #storelocator_country {
      display: none !important;
    }
    .selectBox {
      &.country {
        display: none !important;
      }
    }
  }
}

.product-brief {
  &__bottom-mobile {
    .product-brief {
      &__add-to-bag {
        .product {
          &__notify-me {
            >li .button {
              height: auto;
            }
          }
        }
      }
    }
  }
}

.postal_code_container {
  input {
    padding: 5px 30px;
  }
  label {
    margin-#{$ldirection}: 20px;
  }
  .icon-search {
    position: relative;
    #{$ldirection}: 0.5rem;
    top: -2.1rem;
  }
  .icon-arrow_down {
    position: relative;
    top: -2rem;
    float: $rdirection;
    #{$rdirection}: 10px;
  }
}
